.calendar {
    display: flex;
    flex-direction: column;
    margin-left: -12%;
    position: fixed;
    background-color: #ffff;
    width: 80%;
    border-radius: 20px;
    box-shadow: 0px 0px 450px 0px;
    overflow: hidden;
    top: 100px;
    color: black;
}
.wrapper {
    position: fixed;
    width: 80%;
    margin-bottom: 30px;
    background-color: #fbfbfb;
    z-index: 2;
    border-bottom: solid 1px;
    border-radius: 4px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.hourSlot{
    border-bottom: none !important;
}
.closeCal {
    position: absolute;
    right: 10px;
    top: 10px;
    border: solid 1px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    box-shadow: 3px 3px 10px -5px;
}
.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 20px auto;
}
.next-prev-button {
    background-color: var(--quility-extra-dark-green);
    color: #fbfbfb;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 3px 3px 15px -5px var(--quility-extra-dark-green);;
    font-size: 18px;
    width: 100px;
    font-weight: 700;
    height: 40px;
}
#popupCalendarList {
    height: 35px;
    width: 225px;
    color: inherit;
    font-family: inherit;
    border-radius: 5px;
    box-shadow: 3px 3px 15px -5px #003580;
    font-size: 17px;
}
.makeDefault {
    border: solid 1px;
    text-align: center;
    width: 150px;
    margin: auto;
    margin-top: 10px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    box-shadow: 3px 3px 15px -5px;
    cursor: pointer;
}
.calendar-header {
    display: flex;
}
.date {
    flex: 1;
    text-align: center;
    border: 1px solid var(--quility-extra-dark-green);
    padding: 10px;
    background-color: var(--quility-extra-dark-green);
    font-weight: bold;
    cursor: pointer;
    color: #fbfbfb;
}
.day {
    flex: 1;
    text-align: center;
    padding: 10px;
}
.timeline {
    display: flex;
    flex-direction: row;
    margin-top: 200px;
    height: 500px;
    overflow: auto;
}
.largeWrap {
    flex: 1;
    border-right: solid 1px;
    position: relative;
    height: fit-content;
    overflow: hidden;
}
.hour {
    flex: 1;
    text-align: center;
    border-top: 1px solid #ccc;
    height: 45px;
    position: relative;
}
.halfHourLine {
    height: 50%;
}
.halfHour{
    line-height: 25px;
}
.bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.today{
    background-color: var(--quility-color) !important;
}
.timeSelect{
    border-radius: 5px;
    border: solid 1px #031e4e;
    height: 35px;
    text-indent: 10px;
    font-size: 16px;
    margin: 2px 0px;
    box-shadow: 3px 3px 10px -5px;
    color: inherit;
    font-family: inherit;
    width: 120px;
}
.spacer{
    margin:10px;
}
.event{
    position: absolute;
    left: 5%;
    width: 90%;
    background-color: var(--quility-color);
    border: 1px solid var(--quility-border-color);
    border-radius: 4px;
    padding: 2px 4px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 12px;
    color: var(--quility-light-text);
}

.cancelButton{
    display: inline-block;
    width: 200px;
    border: 4px solid var(--quility-button-color-destructive);
    background-color:var(--quility-button-color-destructive);
    margin: 0px 10px;
}
.saveButton{
    display: inline-block;
    width: 200px;
    border: 4px solid var(--quility-button-color);
    margin: 0px 10px;
}
.eventWindow{
    position: fixed;
    top: 0;
    background-color: #ffffff;
    z-index: 6;
    width: 50%;
    height: 100%;
    margin: auto;
    transform: translateX(50%);
    border-right: solid 1px;
    border-left: solid 1px;
    box-shadow: 0px 0px 20px 0px;
    overflow: auto;
    left: 0;
}
.participantWrapper{
    margin: 5px 0px;
}
.eventTextarea{
    height: 200px !important;
}
.remove{
    border: solid 1px;
    width: 20px;
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 3px 3px 10px -5px;
}
.openCal{
    display: block !important;
}
.calendarWrapper{
    position: fixed;
    z-index: 2;
    display: none;
    left: 0;
    top: 0;
    margin-left: 22%;
}
.eventItemWrapper{
    text-align:center;
    margin:20px;
}
.editEventItemTitle{
    font-weight: 700;
    font-size: 18px;
    margin: 10px;
}
.eventInput{
    border-radius: 5px;
    border: solid 1px #031e4e;
    height: 35px;
    text-indent: 10px;
    font-size: 16px;
    margin: 2px 0px;
    box-shadow: 3px 3px 10px -5px;
    color: inherit;
    font-family: inherit;
    width:80%;
}
.selectCalendarPopup{
    width: 80%;
    position: absolute;
    background-color: #ffffff;
    height: 80%;
    border: solid 1px;
    border-radius: 5px;
    margin-left: 10%;
    margin-top: 3%;
    z-index: 2;
    box-shadow: 3px 3px 25px -5px black;
}

.saveButtonInactive{
    display: inline-block;
    width: 200px;
    border: 4px solid var(--background-color);
    margin: 0px 10px;
}