
.signupLogo{
    max-height: 220px;
    text-align: center;
    margin: auto;
    width: 80%;
    max-width: 500px;
    padding: 20px;
}
.shorten{
    width: 80%;
}
.signUpWrapper{
    width: 70%;
    margin: auto;
    text-align: center;
    max-width: 900px;
}
.completeSignUpWrapper{
    background-color: var(--quility-color);
    height: fit-content;
    min-height: 100%;
}
.searchMe{
    position: absolute;
    display: inline-block;
    height: 30px;
    border-radius: 5px;
    line-height: 30px;
    padding: 0px 10px;
    margin-left: 30px;
    cursor: pointer;
    user-select: none;
}
.existingAgent{
    width: 100%;
    text-align: center;
    color: #b00000;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
}
.goToLogin{
    width: fit-content;
    padding: 0px 10px;
    line-height: 35px;
    margin: auto;
    border-radius: 5px;
    user-select: none;
    margin-top: 20px;
}
.noExist{
    display: block;
    color: #bf0000;
}
.submitSubscription{
    display: inline-block;
    border-radius: 5px;
    line-height: 40px;
    padding: 0px 10px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.contentWrapper{
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    border: 1px #D0D0D0 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    margin-bottom: 16px
}

.contentTitleWrapper{
    align-self: stretch;
    padding: 16px 24px;
    background: #F1F1F1;
    border-bottom: 2px #F1F1F1 solid;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: inline-flex
}

.contentTitle{
    flex: 1 1 0;
    color: #2F2F2F;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 700;
    word-wrap: break-word
}

.conentBodyWrapper{
    align-self: stretch;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    display: flex
}

.contentBodyInputLabel{
    color: #707070;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    word-wrap: break-word;
}

.conentBodyInputError{
    align-self: stretch;
    color: #DE3F35;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 400;
    word-wrap: break-word;
    text-align: left;
}

.contentButtonLabel{
    text-align: center;
    color: white;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    word-wrap: break-word;
}

.contentBodyNestedInputWrapper{
    align-self: stretch;
    height: 66px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
}

.contentBodyInputLabelWrapper{
    justify-content: flex-start;
    align-items: flex-end;
    gap: 4px;
    display: inline-flex;
}

.contentBodyOuterNestedInputWrapper{
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: inline-flex;
}

.contentBodyInnerFlexNestedInputWrapper{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: inline-flex;
}

.contentBodySelect{
    height: 31px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    padding: 5px;
    color: var(--quility-dark-text);
}

.conentBodyPasswordError{
    width: 100%;
    color: #DE3F35;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 400;
    word-wrap: break-word;
}

#productDetailGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    padding: 20px;
    outline: solid #f1f1f1;
}

#productFeaturesGrid{
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 5px;
}

.contentTermsText{
    text-align: left;
    color: black;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 400;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
}

.contentCheckBoxWrapper{
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: inline-flex;
    padding-left: 24px;
    padding-bottom: 16px;
    cursor: pointer;
}

.contentCheckbox{
    font-size: 16px;
    color: #008c00;
}

.contentViewTermsText{
    color: black;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 700;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
}