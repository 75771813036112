.queueModuleTab{
    width: 40px;
    height: 40px;
    position: fixed;
    margin-top: 215px;
    text-align: center;
    line-height: 40px;
    background-color: var(--quility-dark-green);
    color: #ffffff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0px;
    box-shadow: 3px 3px 10px -7px;
    cursor: pointer;
    transition: all .5s;
    z-index: 1;
    right: 0;
}
.calendarSelectData{
    cursor: pointer;
    display: inline-block;
    width: 30%;
}
.calendarSelectLine{
    padding: 5px 0px;
    border-bottom: solid 2px #f1f1f1;
    font-size: 14px;
    color: var(--quility-dark-text);
    vertical-align: middle;
}
.calendarSelectLineTitle{
    font-weight: 700;
    display: inline-block;
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}
.queueModuleTab:hover{
    width:200px;
}
.queueWrapper{
    width: 350px;
    height: calc(100% - 51px);
    position: fixed;
    z-index: 3;
    border-right: solid 1px #c5d9f0;
    transition: all .5s;
    background-color: var(--background-color);
    right: 0;
    top: 51px;
}
.checkBox{
    display:inline-block;
    width: '20px';
    height: '20px';
    border: '1px solid #000';
    border-radius: '50%';
    display: 'inline-block';
}
.queueItemName{
    display: inline-block;
    vertical-align: middle;
}
.queueItem{
    margin: 9px auto;
    border-radius: 10px;
    transition: all .5s;
}
.queueInner{
    display: inline-block;
    vertical-align: middle;
}
.queueTitle{
    font-size: 22px;
    display: inline-block;
    max-width: 350px;
    line-height: 50px;
    cursor: pointer;
    border-bottom: none;
    background-color: var(--quility-dark-green);
    color: #ffff;
    min-width: 300px;
    text-align: left;
}
.titleInner{
    width: 90%;
    border-bottom: solid 1px #97b9b6;
    font-size: 30px;
    font-weight: 700;
    margin: 5px auto;
}
.actualQueue{
    background-color: var(--quility-dark-green);
    color: #ffffff;
    max-width: 350px;
    flex: 1 1;
    min-width: 300px;
    overflow: auto;
    position: relative;
}
.activeTab{
    background-color: #00357f !important;
}
.actualName{
    width: 200px;
    display: inline-block;
}
.categoryBlock{
    border: solid 1px #5085ce;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 0px 5px;
    cursor: pointer;
    background-color: #3a69aa;
}
.queueCategories{
    padding: 15px 0px;
}
.startSession{
    display: inline-block;
}
.fullScreen{
    width:100%;
}
.dialSessionButtonWrapper{
    text-align: center;
    margin: 10px 0px 20px;
}
.centerQueue{
    flex: 2 1;
    background-color: var(--quility-block-background-color);
    margin: 15px;
    position: relative;
    border: solid 2px var(--quility-border-color);;
    border-radius: 3px;
    overflow: hidden;
}
.innerQueueWrapper{
    height: 100%;
    width: 100%;
    display: flex;
}
.rightQueue{
    flex: 1.3 1;
    background-color: var(--quility-block-header-background-color);
    margin: 45px 15px 0px 0px;
    position: relative;
    border: solid 2px var(--quility-border-color);
    border-radius: 3px;
    overflow: hidden;
    height: calc(100% - 64px);
}
.rightHeaderWrapper{
    width: 90%;
    margin: 20px;
    line-height: 45px;
    background-color: var(--quility-nested-block-header-background-color);
    text-align: left;
    border-radius: 3px;
    color: var(--quility-dark-text);
    display: flex;
    justify-content: space-evenly;
}
.queueHeaderBlock{

    font-size: 15px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    /*text-align: center;
    font-size: 16px;
    display: inline-block;
    border: solid 1px #567CB1;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    line-height: 50px;
    margin-left: 1px;
    cursor: pointer;
    border-bottom: none;
    background-color: #567CB1;
    color: #ffff;
    flex: 1;
    font-weight: 700;
    user-select: none;*/
}
.scriptDropWrapper{
    text-align: center;
    padding: 20px;
}
.scriptContainerHeader{
    background-color: var(--quility-nested-block-background-color);
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: var(--quility-dark-text);
    -webkit-user-select: none;
    user-select: none;
    border-radius: 5px;
}
.scriptContainer{
    height: 67%;
    width: 90%;
    margin: auto;
    position: relative;
    /*border: solid 2px #f1f1f1;*/
    border-radius: 5px;
    margin-bottom: 5px;
}
.scriptContainerContent{
    background-color: var(--quility-nested-block-background-color);
    height: 90%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    width: 100%;
    overflow: auto;
    color: var(--quility-dark-text);
}
.entireScriptWrapper{
    height: 86%;
}
.leadInformationBlock{
    font-size: 16px;
    color: #313131;
    font-weight: 700;
    cursor: pointer;
    width: 90%;
    border-radius: 12px;
    background-color: #D9F2ED;
    text-indent: 16px;
    border: 1px solid #DCE5EF;
    text-align: left;
    margin: 20px auto;
    height: 45px;
    overflow: hidden;
    transition: all .5s;
}
.leadInfoTitle{
    font-size: 20px;
    margin: 10px 0px;
}
.doubleLine{
    width: calc(50% - 15px);
    display: inline-block;
    vertical-align: top;
}
.leadInfoLine{
    font-size: 13px;
    padding: 10px 10px;
}
.mainInfo{
    background-color: #F0EDFD;
    border: 1px solid #DCE5EF;
    font-size: 16px;
    color: #313131;
    font-weight: 700;
    cursor: pointer;
    width: 90%;
    border-radius: 12px;
    text-indent: 16px;
    text-align: left;
    margin: 20px auto;
    height: 45px;
    overflow: hidden;
    transition: all .5s;

}
.dialAmountSelect{
    border: solid 1px var(--quility-extra-dark-green);
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    padding: 2px 10px;
    margin-right: 5px;
    cursor: pointer;
    background-color: var(--quility-extra-dark-green);
    vertical-align: middle;
}
.dialAmountWrapper{
    position: absolute;
    top: 25px;
    left: -1px;
    border: solid 1px var(--quility-medium-dark-green);
    display: inline-block;
    border-radius: 5px;
    padding: 0px 10px;
    cursor: pointer;
    background-color: var(--quility-medium-dark-green);
}
.dialAmountSelectBox{
    position: relative;
    border: solid 1px #5085ce;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 0px 5px;
    cursor: pointer;
    background-color: #3a69aa;
}
.currentLead{
    width: calc(100% - 80px);
    display: inline-block;
    border: solid 1px;
    outline: solid 5px #c6fdf1;
}
.queueConvoHeader{
    display: flex;
    color: #031f4e;
}
.conversationTitle{
    line-height: 30px;
    font-weight: 700;
    padding: 5px;
    font-size: 14px;
    display: flex;
    position:relative;
}
.queueConversationButton{
    flex: 1 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
}
.queueConversationInnerWrapper{
    background-color:var(--quility-nested-block-header-background-color);
    height: calc(100% - 180px);
    display: flex;
    margin: auto;
    flex-direction: column-reverse;
    overflow: auto;
    cursor: initial;
    min-height: 200px;
}
.queueConvoWrapper{
    border-radius: 5px;
    height: calc(100% - 102px);
    width: 90%;
    margin: auto;
    color: black;
}
.queueConversationWrapper{
    height: 100%;
    /*background-color: var(--quility-left-side-bar-color);*/
}
.emailWrapper{
    position: absolute;
    height: 0%;
    transition: all .5s;
    border: solid 1px;
    width: 70%;
    background-color: #fbfbfb;
    margin-top: 50px;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    overflow: auto;
    border: solid 1px var(--quility-border-color);
    box-shadow: 3px 3px 10px -5px var(--quility-burst-color);
    z-index: 1;
}
.sentImageWrapper{
    height:100px;
}
.convoBubble{
    border-radius: 3px;
    display: inline-block;
    margin: 5px;
    max-width: 70%;
    padding: 10px 20px;
    text-align: left;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    cursor:pointer;
}
.messagefrom{
    text-align: right;
}
.backgroundfrom{
    background-color: var(--quility-extra-dark-green);
    color: var(--quility-light-text);
}
.backgroundto{
    background-color: var(--quility-block-background-color);
    border-color: var(--quility-border-color);
}
.sendButton{
    display: inline-block;
    width: 40px;
    text-align: center;
    color: var(--quility-color);
    font-size: 22px;
    line-height: 44px;
    vertical-align: top;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 50%;
}
.timestamp{
    font-size: 13px;
    position: absolute;
    margin-left: 10px;
    border: solid 1px;
    padding: 2px 5px;
    line-height: normal;
    border-radius: 3px;
    cursor:pointer;
    font-weight:normal;
}
.sendButton:not(.disabled):hover{
    background-color:var(--quility-dark-green);
}
.messageInput{
    border: solid 1px;
    width: 95%;
    border-radius: 10px;
    min-height: 40px;
    max-height: 150px;
    font-family: inherit;
    font-size: 16px;
}
.inputBarWrapper{
    display: inline-block;
    margin-top: 5px;
    vertical-align: top;
    width: calc(100% - 50px);
    text-align: center;
}
.optionButton{
    display: inline-block;
    color: var(--quility-color);
    font-size: 24px;
    width: 50px;
    text-align: center;
    line-height: 40px;
}
.queuePhoneButton{
    width: 30px;
    height: 30px;
    display: inline-block;
    border: solid 1px;
    line-height: 30px;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
    font-size: 16px;
    color: var(--quility-button-color);
}
.phoneButtons{
    width: 100%;
    margin-top: 10px;
}
.disabled{
    color: #aea5a5;
    cursor:default !important;
}
.filterBlockWrapper{
    position: relative;
    padding-top: 20px;
    color: #00357E;
}
.numberSelectBox{
    border: 1px solid #707070;
    border-radius: 6px;
    opacity: 1;
    height: 28px;
    width: 100%;
}
.fromNumWrapper{
    flex: 1;
}
.toNumWrapper{
    flex: 1;
}
.hidden{
    display:none;
}
.statusTypeBlock{
    /*flex: 1 1;
    max-width: calc(21% - 8px);
    border: solid 1px #acd2ff;
    margin: 3px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 0px;
    height: -moz-fit-content;
    height: fit-content;*/
    flex: 1 1;
    border-radius: 5px;
    background-color: var(--quility-nested-block-background-color);
    height: fit-content;
    width: 20%;
}
.statusButtonBlock{
    padding: unset;
    overflow: auto;
    display: flex;
    bottom: 0px;
    /*position: absolute;*/
    width: 96%;
    text-indent: unset;
    left: 0;
    margin-left: 2%;
}
.typeHolder{
    display:flex;
    text-align: center;
    font-weight:700;
}
.statusItem{
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    line-height: 33px;
    margin: 5px;
    overflow: hidden;
    text-indent: 0;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    flex: 1 1;
    text-align: center;
}
/*.statusItem{
    background-color: var(--quility-block-background-color);
    border-radius: 5px;
    color: var(--quility-dark-text);
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 33px;
    margin: 10px 5px;
    overflow: hidden;
    text-indent: 0;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;

}*/
.dispositionTabz{
    border: solid 1px;
    height: 70px;
    line-height: 70px;
    background-color: #d9f2ed;
    position: absolute;
    width: 50px;
    font-size: 26px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    cursor: pointer;
}
.tabIconHolder{
    display: inline-block;
    width: 50px;
    vertical-align: top;
    text-align: center;
    background-color: #d9f2ed;
    height: 60px;
    margin-left: -30px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    line-height: 60px;
    font-size: 22px;
    border: solid 1px #4bb6a6;
    cursor: pointer;
}
.dialOptionsHolder{
    text-align: left;
    width: 90%;
    margin-left: 5%;
}
.quilityDropDown{
    width: 140px;
    background-color: var(--quility-extra-dark-green);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px;
    height: 30px;
    font-family: inherit;
}
.queueItemTitle{
    line-height: normal;
    font-size: 13px;
    font-weight: normal;
}
.dispositionTab{
    position: absolute;
    margin-left: -600px;
    z-index: 0;
    transition:all .5s;
    margin-top: -70px;
}
.dispositionTab:hover{
    margin-left:-550px;
}
.openedDispo{
    margin-left: 0px !important;
}
.leadData{
    font-weight: normal;
    text-decoration: underline;
    height: calc(100% - 60px);
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}
.workFilter{
    display: inline-block;
    text-indent: 0px;
    height: 25px;
    line-height: 25px;
}
.clearQueue{
    margin-left: -150px;
    width: 100px;
    display: inline-block;
    position: absolute;
    margin-top: 5px;
}
.callIcon{
    border-radius: 50%;
    color: var(--quility-color);
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    margin-left: -20px;
    margin-top: 38px;
    position: absolute;
    text-align: center;
    text-indent: 0;
    -webkit-user-select: none;
    user-select: none;
    width: 30px;
}
.callIcon:hover{
    background-color: var(--quility-color);
    color: var(--quility-light-text);
    font-size: 16px;
}
.queueScroll{
    overflow: scroll;
    height: calc(100% - 330px);
    transition: all .5s;
}
.fullScroll{
    height: calc(100% - 125px) !important;
}
.notesButtons{
    display: flex;
    padding-top: 20px;
}
.selectedTab{
    font-weight: 700;
    color: var(--quility-color);
    border-bottom: solid 1px;
}
.notesButton{
    display: inline-block;
    flex: 1 1;
    margin: 0px 10px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    cursor: pointer;
}
.entireNotesWrapper{
    height: 85%;
    width: 90%;
    margin: auto;
}
.notes{
    outline: solid 1px var(--quility-border-color);
    height: 80%;
    width: 96%;
    margin-left: 1%;
    border-radius: var(--quility-border-radius);
    padding: 5px;
    overflow: auto;
    background-color: #ffffff;
}
.notesWrapper{
    height: 100%;
    display:none;
}
.focusButton{
    border-top-right-radius: 200px;
    border-top-left-radius: 200px;
    background: linear-gradient(180deg, #ffffff 80%, var(--quility-dark-green) 20%);
}
.topBarInput{
    font-family: inherit;
    width: 250px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    border: solid 1px var(--quility-color);
    color: var(--quility-dark-text);
}
.selectionOption{
    display: inline-block;
    width: 110px;
    font-weight: 700;
    cursor: pointer !important;
    position: relative;
    margin: 0px 5px;
    border-radius: 5px;
    line-height: 28px;
}
.iconWrapper{
    position:absolute;
    left: 4px;
}
.selectedItem{
    background-color: var(--quility-dark-green);
    color: var(--quility-light-text);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Optional */
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.numberInput{
    height: 30px;
    color: inherit;
    border-radius: 5px;
    width: 40px;
    border: solid 1px;
    text-align: center;
}
.ampmSelector{
    display: block;
    height: 50%;
    font-size: 11px;
    line-height: 20px;
    font-weight: 700;
}
.ampmSelect{
    display: inline-block;
    height: 40px;
    border: solid 1px;
    vertical-align: top;
    width: 40px;
    margin-left: 5px;
    border-radius: 5px;
    margin-top: -5px;display: inline-block;
    height: 40px;
    border: solid 1px;
    vertical-align: top;
    width: 40px;
    margin-left: 5px;
    border-radius: 5px;
    margin-top: -5px;

}
.timeError{
    border-color: red;
}
.attachFileDropZone{
    height: 90%;
    border: dashed 1px;
    width: 96%;
    margin: 2% auto;
}
.attachFileText{
    margin-top: 10%;
}
.objectionWrapper{
    width: 90%;
    margin: auto;
    height: 30%;
    position: relative;
    border: solid 2px #f1f1f1;
    border-radius: 5px;
    margin-bottom: 5px;
}
.objectionHeader{
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #f1f1f1;
}
.objectionContainer{
    height: 100%;
    margin: auto;
    background-color: #ffffff;
    overflow: auto;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0px 5px;
    border: solid 1px #e4effc;
    border-top: none;
}
.objection{
    order: solid 1px var(--quility-color);
    display: inline-block;
    margin: 5px 4px;
    padding: 8px;
    border-radius: var(--quility-border-radius);
    background-color: var(--quility-color);
    color: #ffffff;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
}
.objectionViewWrapper{
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #4368974f;
    width: 100%;
    height: 100%;
}
.objectionInnerWrapper{
    width: 70%;
    max-width: 700px;
    background-color: var(--quility-block-background-color);
    border-radius: 3px;
    height: 300px;
    margin: 100px auto;
    border: solid 1px var(--quility-border-color);
    padding: 10px;
    position: relative;
}
.objectionContent{
    width: 98%;
    margin: 10px auto 30px;
    height: calc(100% - 30px);
    overflow: auto;
}
.objectionTitle{
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
.addNotesIcon{
    display: inline-block;
    font-weight: normal;
    font-size: 15px;
    margin: 0px 5px;
    height: 30px;
    line-height: 30px;
    width: 40px;
    border-radius: 14px;
    cursor: pointer;
}
.addNotesIcon2{
    display: inline-block;
    font-weight: normal;
    margin: 5px 5px 0px;
    height: 30px;
    line-height: 30px;
    width: 70px;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
}
.addNotesIcon:hover{
    background-color:var(--quility-color);
    color:var(--quility-light-text);
    box-shadow: 2px 2px 10px -5px var(--quility-color);
}
.addNotesIcon2:hover{
    background-color:var(--quility-color);
    color:var(--quility-light-text);
    box-shadow: 2px 2px 10px -5px var(--quility-color);
}
.addWrapper{

    margin-top: -5px;
}
.dataButton{
    display: inline-block;
    width: 200px;
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    box-shadow: 3px 3px 10px -5px;
    cursor: pointer;
    margin: 0px 10px;
}
.workflowButton{
    position: absolute;
    left: 20px;
    top: 25px;
    border: solid 3px #9cd8a5;
    line-height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
    background-color: #a1ffaf;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor:pointer;
}
.flowDataContent{
    background-color: #fbfbfb;
    width: 80%;
    margin-left: 10%;
    height: 250px;
    border-radius: 10px;
    border: solid 1px;
    margin-top: 87px;
    box-shadow: 3px 3px 50px 0px #003580;
    position:relative;
}
.flowDataWrapper{
    background-color: #00358052;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 10px;
    color: var(--quility-dark-text);
}
.flowDataInfoTitle{
    display: inline-block;
    font-weight: 700;
    color: inherit;
    width: 150px;
}
.flowDataInfoInfo{
    display: inline-block;
}
.flowDataInfoWrapper{
    margin: 10px 0px 10px 30px;
}
.attachedFile{
    border: solid 1px var(--quility-color);
    width: 70%;
    text-align: left;
    text-indent: 10px;
    border-radius: var(--quility-border-radius);
    margin: 10px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--quility-color);
    color: var(--quility-light-text);
    cursor: pointer;
}
.filterQueueOpen{
    height: 350px;
    overflow: auto;
    width: 90%;
    margin: 5px auto;
    border-radius: 5px;
}
.queueFilterButtons{
    width: 90%;
    margin: auto;
}
.queueItemWrapper{
    display:inline-block;
}
.textTemplateHolder{
    position: absolute;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 22px;
    font-weight: unset;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
}
.textTemplateHolder:hover{
    background-color: #3a69aa;
    color:#ffffff;
}
.textTemplateSelection:hover{
    background-color:#f1f1f1;
    cursor: pointer;
}
.textTemplateSelection{
    padding: 10px 10px;
}
.textTemplateWrapper{
    margin-top: 42px;
    border: solid 1px #f1f1f1;
    padding: 10px 0px;
    position: absolute;
    height: 300px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 3px 3px 10px -5px;
    border-radius: 5px;
}
.previewText{
    background-color: #bcfdf4;
    padding: 5px;
    display:none;
}
.previewTextTitle{
    border: solid 1px;
    display: inline-block;
    padding: 0px 10px;
    border-radius: var(--quility-border-radius);
    margin: 5px;
    color: var(--quility-light-text);
    background-color: var(--quility-color);
    font-weight: 600;
    cursor: pointer;
}
.previewTextTitle:hover + .previewText {
    display:block;
}
.demo {
    width: 100px;
    min-height: 20px;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: solid 1px var(--quility-border-color);
    width: 95%;
    border-radius: 10px;
    min-height: 80px;
    max-height: 60px;
    font-family: inherit;
    font-size: 16px;
    background-color: var(--quility-input-background-color);
    text-align: left;
    padding: 5px;
    margin-left: 5px;
}
.completeSession{
    position: absolute;
    width: 50%;
    background-color: var(--quility-block-background-color);
    height: 400px;
    margin-top: 100px;
    margin-left: 25%;
    border-radius: var(--quility-border-radius);
    border: solid 2px var(--quility-border-color);
    box-shadow: 10px 10px 100px -10px;
    text-align: center;
    line-height: 350px;
    font-size: 22px;
    font-weight: 700;
}
.sessionEvent{
    top: 50px !important;
    height: calc(100% - 50px) !important;
}
.sendBar{
    width: 100%;
}
.dispositionHolder{
    width: 100%;
    margin: auto;
}
.activeLeadCardButton{
    background-color: var(--quility-button-color);
    color: #ffffff !important;
}
.upcomingCallWrapper{
    width: 200px;
    margin: 10px auto;
    line-height: 30px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
    position: relative;
}
.upcomingOutterWrapper{
    margin: 30px;
}
.calSelectionWrapper{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #16356b52;
    width: 100%;
    height: 100%;
    z-index: 5;
}
.calSelectionContent{
    background-color: #ffffff;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    height: fit-content;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
}

.recName{
    font-size:20px;
    font-weight: 700;
    line-height: normal;
}
.recWrapper{
    height: 87%;
}

.hiddenDescription{
    display: none;
}
.viewRecDescription{
    width:fit-content;
    margin: 10px;
}
.viewRecDescription:hover > .hiddenDescription{
    display:block;
}

.noDeco{
    text-decoration: none;
    color: inherit;
}
#workWindowWrapper{
    background-color: #fbfbfb;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

#workWindowTopBarWrapper{
    height: 70px;
    display: flex;
    background-color: #c5d9f0;
}
.resizable {
    position: relative;
    overflow: hidden;
    border: 1px solid #000;
  }

  .handle {
    width: 10px;
    cursor: ew-resize;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #3498db; /* Adjust color as needed */
  }
.windowBlockHeader{
    background-color: #e4effc;
    height: 50px;
}
.windowBlockTitle{
    line-height: 50px;
    text-align: center;
    font-weight: 700;
}
.windowBlockContent{
    background-color: #ffffff;
    height: calc(100% - 50px);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.windowBlockWrapper{
    width: 30%;
    max-width: 600px;
    border: solid 1px #c5d9f0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 20px;
    min-height: 55px;
    margin-top: 50px;
    color: #02357e;
    -webkit-user-select: none;
    user-select: none;
    display: inline-block;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all .5s;
    height: 40%;
}
.workWindowButton{
    border: solid 1px;
    flex: 1;
    text-align: center;
    line-height: 70px;
    font-weight: 700;
    color: #02357e;
    cursor: pointer;
    border-top: none;
}
.focus{
    width: 100%;
    max-width: unset;
    margin: auto;
    height: 91%;
    margin-top: -5px;
    position: relative;
}
.unfocus{
    position: absolute;
    top: 0;
    margin: unset;
    width: 49%;
    display: inline-block;
    height: 0px;
    min-height: unset;
    max-width: unset;
    left:0px;
    display: none;
}
.unfocus:last-of-type {
    margin-left:50%;
}

.leftSideBarWrapper{
    width: 22%;
    height: 100%;
    border-right: solid 1px #c5d9f0;
    display:inline-block;
}
.workPhoneWrapper{
    height: 20%;
    border-bottom: solid 1px;
}
.leadQueueLead{
    width: 96%;
    height: 80px;
    border-radius: 5px;
    margin: 4px;
    margin-left: 1.5%;
    box-shadow: 3px 3px 10px -5px;
    cursor: pointer;
    border: solid 1px #c5d9f0;
    background: #ffffff;
    color: #00357E;
    vertical-align: middle;
}
.leadQueueName{
    font-weight: 700;
    background-color: #e4effc;
    border-bottom: solid 1px #c5d9f0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    text-indent: 5px;
}
.leadQueueStatus{
    margin-left: 20px;
    line-height: 25px;
    font-size: 14px;
    color: #617cac;
}
.leadQueueContact{
    line-height: 25px;
    margin-left: 20px;
    font-size: 14px;
}

.queueButton{
    width: 80px;
    border: solid 1px;
    border-radius: 5px;
    text-align: center;
    flex: 1 1;
    display: inline-block;
    margin: 25px 5px;
    height: 30px;
    line-height: 30px;
    box-shadow: 3px 3px 10px -5px;
    background-color: #e4effc;
    border: solid 1px #c5d9f0;
}
.queueTextBar{
    display: flex;
    box-shadow: 0px 10px 20px -15px;
}
.leadQueueMessagePreview{
    color: #031e4e9c;
    font-size: 14px;
    height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 25px;
}
.leadQueueWrapper{
    overflow: scroll;
    height: 89%;
}
.leadQueueNameInner{
    width: 80%;
    display: inline-block;
}
.leadQueueOptionHolder{
    display: inline-block;
    width: calc(20% - 5px);
}
.leadQueueOption{
    flex: 1;
}
.leadQueueOptionInner{
    display: flex;
}
.messageModal{
    position: absolute;
    border: solid 0.5px #c5d9f0;;
    top: 50px;
    left: 22%;
    height: calc(100% - 50px);
    width: 0;
    transition: all .5s;
    overflow: hidden;
}
.showMessageModal{
    width: 50%;
    border: solid 1px #c5d9f0;
    border-top: none;
    border-bottom: none;
    background-color:#fbfbfb;
}
.conversationButton{
    border: solid 1px;
    width: 160px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    margin: 10px;
    max-width: 200px;
    cursor:pointer;
}
.toWrapper{
    display: flex;
    justify-content: center;
}
.conversationHolder{
    display:none;
    overflow: auto;
    height: 80%;
}
.viewing{
    background-color: #f8fafe;
    overflow: auto;
    margin: auto;
    height: calc(100% - 150px);
    width: calc(100% - 12px);
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
}
.conversationInputWrapper{
    height: 90px;
}
.conversationInputBarWrapper{
    height: 50%;
    border: solid 1px #02357e;
    width: 70%;
    border-radius: 10px;
    margin-top: 3%;
    margin-left: calc(30% - 2px);
    color: #02357e;
}
.conversationInput{
    height: calc(100% - 3px);
    width: 85%;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    color: inherit;
    font-size: 20px;
    font-family: inherit;
}
.innerConvoWrap{
    display: block;
}
.conversation{
    height: 100%;
    position: relative;
    z-index: 1;
}
.sessionConvoBubble{
    border-radius: 10px;
    max-width: 60%;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    margin: 35px 5px;
    overflow-wrap: break-word;
    border: solid 1px;
    font-size: 16px;
}
.sessionfrom{
    border-color: #c5d9f0;
    margin-left: 37%;
    background-color: #fbfbfb;
    box-shadow: 3px 3px 10px -5px;
}
.sessionto{
    background-color: #e4effc;
    border-color: #c5d9f0;
    box-shadow: 3px 3px 10px -5px;
}
.centerBarWrapper{
    width: 50%;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    border-right: solid 1px #c5d9f0;
}
.statusName{
    font-size: 15px;
    margin-left: 10px;
    border-radius: 10px;
    background-color: var(--quility-accent-background);
    padding: 4px 15px;
    font-weight: 700;
    margin-top: -13px;
    vertical-align: super;
}
.addressSlot{
    margin: 2px;
}
.leadType{
    text-align: center;
}
.leadCardSubTitle{
    font-size: 18px;
    font-weight: 700;
    margin-left: 20px;
    background-color: #ffffff;
    color: #02357e;
    z-index: 0;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 5px;
    border-radius: 5px;
    border: solid 0.75px #69afff;
    border-bottom: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    max-width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: -49px;
}
.leadCardSubTitleStable{
    font-size: 21px;
    font-weight: 700;
    margin-left:20px;
    margin-top: 20px;
}
.leadCardSubNode{
    border: solid 1px;
    width: 90%;
    margin: 30px auto;
    height: 50px;
    line-height: 50px;
    text-indent: 20px;
    border-radius: 10px;
    background-color: #4bb6a6;
}
.cardInfoItem{
    margin: 3px 0px;
}
.cardInfoItem:hover > .hoverIcon{
    display:inline-block;
}
.leadCardSubTitle:hover > .hoverIcon{
    display:inline-block;
}
.returnToLeadCard{
    position: absolute;
    margin-top: -10px;
    height: 30px;
    line-height: 30px;
    padding: 0px 10px;
    user-select: none;
    cursor:pointer;
    border-radius: 5px;
}
.addressHolder{
    text-align: left;
    font-size: 14px;
    display: inline-block;
    width: 40%;
    margin-bottom: 5px;
    color: var(--quility-dark-text);
}
.leadCardAddressWrapper{
    background-color: var(--quility-block-header-background-color);
    border-bottom: solid 1px;
    width: 96%;
    margin-left: 2%;
    border-color: var(--quility-border-color);
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.badgeHolder{
    width: 100%;
    vertical-align: middle;
}
.double{
    width: calc(50% - 40px);
    display: inline-block;
    vertical-align: top;
}
.emptyInfo{
    height:0px;
    overflow:auto;
    transition:all .5s;
}
.isOpen{
    height: 140px;
    margin-top: -15px;
}

.openArrow{
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}
.rotate{
    transform: rotate(180deg);
}
.transition{
    transition: all .5s;
}
.quilityActiveTab{
    color: var(--quility-button-color);
    font-weight: 900;
    text-decoration: underline;
}
.iconWrapper2{
    position: absolute;
    top: -10px;
    left: -40px;
    cursor: pointer;
}

.demoInactive{
    width: 100px;
    min-height: 20px;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: solid 1px var(--quility-border-color);
    width: 95%;
    border-radius: 10px;
    min-height: 80px;
    max-height: 60px;
    font-family: inherit;
    font-size: 16px;
    background-color: var(--background-color);
    text-align: left;
    padding: 5px;
    margin-left: 5px;
}

.addEventParticipant{
    cursor: pointer;
    width: fit-content;
    margin: auto;
}

.addEventParticipantWrapper{
    margin-top: -5px;
}

.addEventParticipant:hover{
    background-color: var(--quility-color);
    color: var(--quility-light-text);
    border-radius: 5px;
}