/* Custom properties */
:root {
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
  }

  /* Wrapping */
  .Tooltip-Wrapper {
    position: absolute;
    width: 50%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
  }

  /* Absolute positioning */
  .Tooltip-Tip {
    position: relative;
    left: 50%;
    transform: translateX(-25%);
    z-index: 100;
    align-self: stretch;
    padding: 16px;
    background: white;
    border-radius: 8px;
    border-color: rgba(16, 24, 40, 0.06);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06)
  }

  .Tool-Tip-Content {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
  }

  .Tool-Tip-Content-Title {
    align-self: stretch;
    color: #2F2F2F;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 600;
    word-wrap: break-word
  }

  .Tool-Tip-Content-Body {
    align-self: stretch;
    color: #BDBDBD;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    word-wrap: break-word;
  }

  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }

  /* Absolute positioning */
  .Tooltip-Tip.top {
    top: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--quility-block-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--quility-block-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--quility-block-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--quility-block-background-color);
  }
